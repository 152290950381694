export const moreContextItems = [
  {
    key: 'marketing-settings',
    icon: '',
    text: 'Marketing settings',
  },
  {
    key: 'payment-settings',
    icon: '',
    text: 'Payment settings',
  },
];
