import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUSES } from 'constants/common';

import * as api from 'api/methods';

import { currentCourseSelector } from 'store/course/selectors';
import { updateLessonById } from 'store/lesson/actions';
import { currentLessonSelector } from 'store/lesson/selectors';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';
import { getChangedPropertiesByKeys } from 'helpers/getChangedPropertiesByKeys';

import { useStatusChange } from 'hooks/useStatusChange';
import { useModal } from 'hooks/useModal';

import { convertDataToApi } from './helpers/convertDataToApi';
import { getChangedProperties } from './helpers/getChangedProperties';
import { getResyncData } from './helpers/getResyncData';

import FigmaLessonPage from './FigmaLessonPage';
import { useFigmaFileImages } from './FigmaLessonPage.hooks';

const FigmaLessonPageContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courseId, lessonId } = useParams();

  const course = useSelector(currentCourseSelector);
  const lesson = useSelector(currentLessonSelector);

  const [saveLoading, setSaveLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    lesson.fileId && lesson.filePageId ? 2 : 1,
  );
  const [currentTask, setCurrentTask] = useState('INITIAL');
  const [resyncLoading, setResyncLoading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);
  const [lastActionAt, setLastActionAt] = useState(lesson.updatedAt);

  const [onStatusContextItemClick] = useStatusChange(lesson);
  const [figmaFileImagesLoading, figmaFileImages] = useFigmaFileImages(
    lesson.fileId,
  );

  const [lessonsOpen, openLessons, closeLessons] = useModal({
    defaultOpen: false,
  });

  const handleAutoSaveSubmit = async (values) => {
    if (lessonId !== values.id) {
      return;
    }
    if (currentStep !== 2) {
      return;
    }
    const data = convertDataToApi(values);

    const changedPropertiesKeys = getChangedProperties({
      currentLesson: lesson,
      nextLesson: data,
    });

    if (changedPropertiesKeys && changedPropertiesKeys.length > 0) {
      setAutoSaveLoading(true);

      const updatedLesson = await api.updateFigmaLessonById({
        lessonId,
        data: getChangedPropertiesByKeys({ data, keys: changedPropertiesKeys }),
      });

      setLastActionAt(updatedLesson.updatedAt);

      setAutoSaveLoading(false);
    }
  };

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    try {
      const data = convertDataToApi(values);

      const changedPropertiesKeys = getChangedProperties({
        currentLesson: lesson,
        nextLesson: data,
      });

      const updatedLesson = await dispatch(
        updateLessonById({
          lessonId,
          lessonType: lesson.type,
          data: {
            ...data,
            resultPreview: data.tasks[data.tasks.length - 1].preview,
          },
          changedPropertiesKeys,
        }),
      ).unwrap();

      setLastActionAt(updatedLesson.updatedAt);

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.lesson_saved.title'),
          text: getTranslation('common.toast.lesson_saved.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const handleResyncClick = async (values, { setFieldValue }) => {
    const { fileId, filePageId, designType, tasks, initialState } = values;

    setResyncLoading(true);

    const resyncData = await getResyncData({
      fileId,
      filePageId,
      currentTasks: tasks,
      currentInitialState: initialState,
      designType,
    });

    if (!resyncData) {
      setResyncLoading(false);

      return;
    }

    const [
      updatedTasks,
      updatedInitialState,
      updatedDesignType,
      lessonMaterials,
    ] = resyncData;

    await api.saveFigmaLessonMaterials({
      lessonId: lesson.id,
      data: {
        materials: lessonMaterials || [],
      },
    });

    setFieldValue('initialState', updatedInitialState);
    setFieldValue('tasks', updatedTasks);
    setFieldValue('designType', updatedDesignType);

    setResyncLoading(false);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.resync_success.title'),
        text: getTranslation('common.toast.resync_success.text'),
      },
    });
  };

  const handlePreviewClick = () => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/preview`);
  };

  const handleContinueCreationClick = async (values, { setFieldValue }) => {
    const { fileId, filePageId, designType, tasks, initialState } = values;

    setContinueLoading(true);

    const [
      updatedTasks,
      updatedInitialState,
      updatedDesignType,
      lessonMaterials,
    ] = await getResyncData({
      fileId,
      filePageId,
      currentTasks: tasks,
      currentInitialState: initialState,
      designType,
    });

    await api.saveFigmaLessonMaterials({
      lessonId: lesson.id,
      data: {
        materials: lessonMaterials || [],
      },
    });

    setFieldValue('initialState', updatedInitialState);
    setFieldValue('tasks', updatedTasks);
    setFieldValue('designType', updatedDesignType);

    const data = convertDataToApi({
      title: '',
      fileId: values.fileId,
      filePageId: values.filePageId,
      designType: updatedDesignType,
      free: values.free,
      initialState: updatedInitialState,
      tasks: updatedTasks,
      resultPreview: updatedTasks[updatedTasks.length - 1].preview,
    });

    const updatedLesson = await dispatch(
      updateLessonById({
        lessonId,
        lessonType: lesson.type,
        data,
        changedPropertiesKeys: [
          'title',
          'tasks',
          'free',
          'fileId',
          'filePageId',
          'initialState',
          'designType',
          'resultPreview',
        ],
      }),
    ).unwrap();

    setLastActionAt(updatedLesson.updatedAt);

    setContinueLoading(false);

    setCurrentStep(2);
  };

  const handleTaskClick = (nextTask) => {
    setCurrentTask(nextTask);
  };

  const handleInitialStateClick = () => {
    setCurrentTask('INITIAL');
  };

  const handleGoodToKnowClick = () => {
    setCurrentTask('GOOD_TO_KNOW');
  };

  const lessonReadOnly = lesson.status !== STATUSES.DRAFT;
  const loading = figmaFileImagesLoading;

  return (
    <>
      <FigmaLessonPage
        {...props}
        loading={loading}
        saveLoading={saveLoading}
        autoSaveLoading={autoSaveLoading}
        resyncLoading={resyncLoading}
        continueLoading={continueLoading}
        lessonsOpen={lessonsOpen}
        readOnly={lessonReadOnly}
        figmaFileImages={figmaFileImages}
        lesson={lesson}
        lastActionAt={lastActionAt}
        currentStep={currentStep}
        currentTask={currentTask}
        courseTitle={course.title}
        courseStatus={course.status}
        onAutoSaveSubmit={handleAutoSaveSubmit}
        onTaskClick={handleTaskClick}
        onInitialStateClick={handleInitialStateClick}
        onGoodToKnowClick={handleGoodToKnowClick}
        onSaveClick={handleSaveClick}
        onResyncClick={handleResyncClick}
        onPreviewClick={handlePreviewClick}
        onContinueCreationClick={handleContinueCreationClick}
        onLessonsBtnClick={openLessons}
        onLessonsCloseClick={closeLessons}
        onStatusContextItemClick={onStatusContextItemClick}
      />
    </>
  );
};

export default React.memo(FigmaLessonPageContainer);
